import { Box, Button, useMediaQuery } from "@mui/material";
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import axios from "axios";
import React, { memo, useEffect, useRef, useState } from "react";
import TableLoader from "../common/TableLoader";

import RefreshIcon from "@mui/icons-material/Refresh";
import '../../assets/css/statistics.css';
import { DataGridPro } from "@mui/x-data-grid-pro";


// custom toolbar with custom default filters
const CustomToolbar = ({fetchList}) => {

    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          printOptions={{
            disableToolbarButton: true,
          }}
        />
        <Box>
          <Button  startIcon={<RefreshIcon />} onClick={fetchList} >Refresh</Button>
        </Box>
      </GridToolbarContainer>
    );
  };


function Statistics({
  rows,
  setRows,
  isFetchingTableData,
  setIsFetchingTableData,
}) {
  const [tempRows, setTempRows] = useState([]);
  // const [mcatString,setMcatString]=useState("");
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const tempRowsRef = useRef(tempRows);  // Use a ref to track tempRows

  useEffect(() => {
    tempRowsRef.current = tempRows;  // Update the ref every time tempRows changes
  }, [tempRows]);


  const columns = [
    { field: "id", headerName: "Sr No.", width: 90 },
    {
      field: "mcat",
      headerName: "MCAT",
      width: 250,
      editable: true,
    },
    {
      field: "num_images_processed",
      headerName: "Processed Images",
      type:"number",
      width: 200,
      editable: true,
    },
  ];

  const fetchList = async () => {
    setRows([]);
    setIsFetchingTableData(true);
    try {
      const response = await axios.get(
        `http://103.104.73.45:5001/get_rapples_stats/`
      );
      //   setRows(response.data.csv_stats);
    //   console.log("response", response);
      const fetchedRows = response.data.csv_stats.map((item, index) => ({
        id: index + 1, // Fallback to index if no unique ID available
        ...item,
      }));
      setRows(fetchedRows);

      setIsFetchingTableData(false);
      setTempRows(fetchedRows);
    } catch (error) {
    //   console.log("error is", error);
      setIsFetchingTableData(false);
    }
  };


  /* function to search in the table
  const searchInTable=(searchString) => {

    setMcatString(searchString);

    if(searchString==="")
   {
    setRows(tempRows);
    return;
   }

    setRows(tempRows.filter((row)=>row.mcat.includes(searchString)));
  }
  */

  useEffect(() => {

    if (!isFetchingTableData && rows.length === 0) fetchList();
    else if(rows.length!==0){
        setTempRows(rows);
    }

    return () => {
        if (tempRowsRef.current.length > 0) {
            setRows(tempRowsRef.current);
          }
         
    };
  }, []);

  return (
    <Box sx={{ height: 680, 
    width: isMobile?"100%":"60%" 
    }} px={1}>
      {isFetchingTableData ? (
        <TableLoader type={"table"} />
      ) : (
        <>
          {/* <Box textAlign={"right"} mb={1}>
            <Button
              variant="contained"
              size="small"
              onClick={fetchList}
              startIcon={<RefreshIcon />}
              sx={{ height: 35 }}
            >
              Refresh
            </Button>
          </Box> */}
          {/* <Box  mb={1}
          
          >
            <input type="text"
           placeholder="Search Mcat"
           className="table-searchbar"
           value={mcatString}
            onChange={(e)=>searchInTable(e.target.value)}
           />
          </Box> */}
          <DataGridPro
          
            rows={rows}
            columns={columns}
            pagination
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 30,
                },
              },
            }}
           
            pageSizeOptions={[20, 30, 40]}
            slots={{
                toolbar: (props) => (
                  <CustomToolbar
                    
                    {...props}
                    fetchList={fetchList}
                  />
                ),
              }}

            disableRowSelectionOnClick
           sx={{
            fontSize:"1rem"
           }}
           
           
          />
        </>
      )}
    </Box>
  );
}

export default memo(Statistics);
