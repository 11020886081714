import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  LinearProgress,
  Paper,
  Slider,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { useEffect, useRef, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";

import brandImage from "../assets/images/knowdis-brand.png";
import { marks } from "../components/HelperFunctions";
import Imagesdata from "../components/ImageData/Imagesdata";
import { SERVER_URL } from "../config";
import axios from "axios";
import Notification from "../components/notification/Notification";
import "../assets/css/loader.css";
import Statistics from "../components/statistics/Statistics";

function Home() {
  const [mcat, setMcat] = useState(null);
  const [quantileThreshold, setQuantileThreshold] = useState(0.2);
  const [isChecked, setIsChecked] = useState(true);
  const [isloaded, setIsloaded] = useState(false);
  const [isAccordionLoaded, setIsAccordionLoaded] = useState(false);
//   const [streaming, setStreaming] = useState(false);
  const [serverImagesData, setServerImagesData] = useState({});
  const [mcatList, setMcatList] = useState([]);
  const [rows, setRows] = useState([]);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [isFetchingTableData, setIsFetchingTableData] = useState(false);
  const eventSourceRef = useRef(null);

  const [tabvalue, setTabValue] = useState("1");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // notification inputs
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [severity, setSeverity] = useState("");

  const valuetext = (value) => {
    setQuantileThreshold(value);
    return `${value}`;
  };

  // fetching streaming response
//   useEffect(() => {
//     //   let eventSource;

//     console.log("streaming", eventSourceRef.current);

//     if (streaming) {
//       const eventSource = new EventSource(
//         `${SERVER_URL}/run_pipeline/?mcat=${mcat}&quantile=${quantileThreshold}&show_remaining_images=${isChecked}`
//       );
//       eventSourceRef.current = eventSource;
//       eventSource.onmessage = function (event) {
//         const newData = JSON.parse(event.data);
//         if (newData.complete) {
//           // Stop streaming and close the event source
//           eventSource.close();
//           setStreaming(false); // Optionally update state to reflect no longer streaming
//         } else {
//           if (isAccordionLoaded) {
//             setIsAccordionLoaded(false);
//             // setStreaming(false);
//           }
//           setServerImagesData((prevData) => {
//             const resultCategory = newData.result;
//             const existingImages = prevData[resultCategory] || [];
//             return {
//               ...prevData,
//               [resultCategory]: [...existingImages, newData],
//             };
//           });
//         }
//       };

//       eventSource.onerror = function (event) {
//         setShowError(true);
//         setErrorMsg("Something went wrong with streaming response");
//         setSeverity("error");
//         eventSource.close();
//         setStreaming(false);
//       };
//     }

//     return () => {
//       // Cleanup function to close the EventSource when the component unmounts or dependencies change
//       if (eventSourceRef.current) {
//         eventSourceRef.current.close();
//       }
//     };
//   }, [streaming]);

  // fetching all the mcats
  const fetchList = async () => {
    try {
      setIsloaded(true);
      const response = await axios.get(`${SERVER_URL}/supported_mcats/`);
      setMcatList(response.data);
      setIsloaded(false);
    } catch (error) {
      setShowError(true);
      setErrorMsg("Facing issue while fetching MCATs");
      setSeverity("error");
      setIsloaded(false);
    }   
  };

  // fetching mcats on first load
  useEffect(() => {
    fetchList();

    return () => {
      setMcatList([]);
      setServerImagesData({});
    };
  }, []);

  // handler to execute streaming response
  const handleStartStreaming = () => {
    if (!mcat || !quantileThreshold) {
      setShowError(true);
      setErrorMsg("Please provide all the inputs");
      setSeverity("error");
      return;
    }

    // setIsAccordionLoaded(true);

    // Close existing stream if it's currently active
    
      if (eventSourceRef.current) {
        setServerImagesData({});
        eventSourceRef.current.close();
      }

      const eventSource = new EventSource(
        `${SERVER_URL}/run_pipeline/?mcat=${mcat}&quantile=${quantileThreshold}&show_remaining_images=${isChecked}`
      );
      eventSourceRef.current = eventSource;
      eventSource.onmessage = function (event) {
        const newData = JSON.parse(event.data);
        if (newData.complete) {
          // Stop streaming and close the event source
          eventSource.close();
        //   setStreaming(false); // Optionally update state to reflect no longer streaming
        } else {
          if (isAccordionLoaded) {
            setIsAccordionLoaded(false);
            // setStreaming(false);
          }
          setServerImagesData((prevData) => {
            const resultCategory = newData.result;
            const existingImages = prevData[resultCategory] || [];
            return {
              ...prevData,
              [resultCategory]: [...existingImages, newData],
            };
          });
        }
      };

      eventSource.onerror = function (event) {
        setShowError(true);
        setErrorMsg("Something went wrong with streaming response");
        setSeverity("error");
        eventSource.close();
        // setStreaming(false);
      };




    // setServerImagesData({});
    // setStreaming(true);
  };

  return (
    <>
      <Notification
        showError={showError}
        setShowError={setShowError}
        errorMsg={errorMsg}
        severity={severity}
      />
      <Box
        sx={{
            mx: isMobile ? 0 : 15,
          mt: 10,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          mb={2}
        >
          <Box>
            <img src={brandImage} width={150} alt="Knowdis Brand" />
          </Box>
          <Box>
            <Typography variant="h4">Rapples V3 (Pipeline)</Typography>
          </Box>
        </Box>

        <TabContext value={tabvalue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Rapple" value="1" />
              <Tab label="Statistics" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Paper elevation={4}>
              <Box sx={{ padding: 3 }}>
                <Box m={1}>
                  <Typography
                    fontWeight={"bold"}
                    display="inline-flex"
                    alignItems="center"
                  >
                    Select MCAT (
                    {isloaded ? (
                      <div className="loader"></div>
                    ) : (
                      `Total: ${mcatList.length}`
                    )}
                    )
                  </Typography>
                </Box>
                <Box
                  m={1}
                  display={"flex"}
                  justifyContent={"center"}
                  gap={"5px"}
                >
                  <FormControl sx={{ minWidth: 120, width: "100%", mb: 2 }}>
                    <Autocomplete
                      disablePortal
                      size="small"
                      id="combo-box-demo"
                      options={mcatList}
                      getOptionLabel={(option) => option} // Specify how to display options
                      sx={{ width: "100%" }}
                      value={mcat}
                      onChange={(e, newValue) => setMcat(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} label="Choose an Option" />
                      )}
                    />
                    {/* autocomplete list */}
                    {isloaded && <LinearProgress color="secondary" />}
                    <FormHelperText
                      fontWeight="bold"
                      sx={{ fontSize: "0.9rem" }}
                    >
                      List updates every minute. Reload to see the latest MCATs.
                    </FormHelperText>
                  </FormControl>

                  {/* loader button */}
                  <IconButton
                    aria-label="Example"
                    // onClick={handleReplay}
                    onClick={fetchList}
                    sx={{
                      border: "1px solid grey",
                      borderRadius: "4px",
                      height: "40px",
                      // marginBottom: "8px",
                      // padding: "4px",
                    }}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Box>

                {/* slider */}
                <Box m={1}>
                  <Typography fontWeight={"bold"}>
                    Quantile Threshold
                  </Typography>
                  <Slider
                    aria-label="Always visible"
                    defaultValue={0.2}
                    getAriaValueText={valuetext}
                    step={0.05}
                    marks={marks}
                    min={0.05}
                    max={1.0}
                    valueLabelDisplay="on"
                    color="success"
                    size="medium"
                  />
                </Box>

                <Box m={1}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked}
                          onChange={(e) => setIsChecked(e.target.checked)}
                        />
                      }
                      label="Show Images above the Quantile"
                    />
                  </FormGroup>
                </Box>

                <Box m={1}>
                  <Button
                    variant="outlined"
                    sx={{ mt: 2 }}
                    onClick={handleStartStreaming}
                    disabled={isAccordionLoaded}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Paper>
            {serverImagesData && (
              <Imagesdata
                serverImagesData={serverImagesData}
                isAccordionLoaded={isAccordionLoaded}
              />
            )}
          </TabPanel>
          <TabPanel value="2">
            <Statistics
              rows={rows}
              setRows={setRows}
              isFetchingTableData={isFetchingTableData}
              setIsFetchingTableData={setIsFetchingTableData}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}

export default Home;
