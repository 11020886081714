import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardMedia,
  CircularProgress,
  Grid,
  Link,
  // Skeleton,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { memo, useEffect, useRef, useState } from "react";
// import { imageData } from "../HelperFunctions";

function LazyImage({ src, alt, display_id }) {
  const imgRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
           
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.disconnect();
      }
    };
  }, []);

  return (
   <Link href={src} target="_blank" sx={{textDecoration:"none"}}>
    <Card sx={{ maxWidth: 500 }}>
      <CardMedia
        ref={imgRef}
        sx={{ height: 300,
          transition: 'opacity 0.5s ease', // optional, for smooth transition
          '&:hover': {
            opacity: 0.5, // change this value to whatever you want on hover
          },
         }}
        image={isVisible ? src : undefined}
        title={alt}
        
      />
      <Typography variant="body1" my={1} fontSize={"bold"}>
        Display ID: {display_id}
      </Typography>
    </Card>
   </Link>
  );
}

function Imagesdata({ serverImagesData,isAccordionLoaded}) {
  const [visibleCounts, setVisibleCounts] = useState({}); // Manage visible counts per accordion
  
  // Update visible count for specific result category
  const increaseVisibleCount = (result) => {
    setVisibleCounts((prev) => ({
      ...prev,
      [result]: (prev[result] || 7) + 7,
    }));
  };

  return (
    <Box mt={4}>

      {Object.entries(serverImagesData).map(
        ([result, images], index) =>
          // Only render Imagesdata if images array is not empty
          images.length > 0 && (
            <Accordion
              defaultExpanded={index === 0 ? true : false}
              sx={{
                mb: 2,
              }}
              key={index}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography variant="h6">{result}</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  maxHeight: "40em", // Set the height to 40em
                  overflow: "auto", // Allow scrolling within the AccordionDetails
                }}
                onScroll={(e) => {
                  const bottom =
                    e.target.scrollHeight - e.target.scrollTop ===
                    e.target.clientHeight;
                  if (bottom && images.length > (visibleCounts[result] || 7)) {
                    increaseVisibleCount(result);
                  }
                }}
              >
                <Box textAlign={"center"}>
                  <Grid container spacing={2}>
                    {images
                      .slice(0, visibleCounts[result] || 7)
                      .map((item, idx) => (
                        <Grid item xs={12} sm={6} md={2} key={idx}>
                          <LazyImage
                            src={item.image_url}
                            alt={"Image"}
                            display_id={item.display_id}
                         
                          />
                        </Grid>
                      ))}
                    {images.length > (visibleCounts[result] || 7) && (
                      <Grid
                        item
                        xs={12}
                        style={{
                          height: 300,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress size={100} />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          )
      )}

      {/* {
        isAccordionLoaded && (
          <Box >
         
          {
            [1,2,3,4].map((item,index)=><Skeleton key={index} animation="wave" height={40}  />)
          }
         
           
        </Box>
        )
      } */}
 
    </Box>
  );
}

export default memo(Imagesdata);
